import { useEffect, useState } from 'react'

// Redux
import { ACTN_hide_login, ACTN_show_register, ACTN_show_forget_pass, ACTN_set_loading } from '../../Redux/SettingsReducer';
import { ACTN_logged_in } from '../../Redux/UserReducer';
import { useDispatch } from 'react-redux';

// react-router-dom
import { useNavigate, useLocation } from 'react-router-dom';

// API
import { API_login_user, API_get_user_messages_count } from '../../apis';

export default function LoginForm() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { pathname } = useLocation();

    // console.log("pathname.indexOf(' / teacher')", pathname.indexOf('/teacher'))

    useEffect(() => {

    }, [])

    const handle_remember = () => {
        setRemember(!remember);
    }

    const handle_user_login = async () => {

        // Validate Email
        const email_regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

        if (!email_regex.test(email.toLowerCase())) {
            setError('Invalid Email');
            return;
        }

        const data = {
            email: email,
            password: password
        }

        dispatch(ACTN_set_loading(true));

        await API_login_user(data)
            .then(async res => {
                if (undefined !== res.loggedin && res.loggedin == false) {
                    dispatch(ACTN_set_loading(false));
                    setError(res.msg);
                    return;
                }

                if (typeof res === 'object') {
                    const user_data = { ...res, "user_email": email, remember };

                    // get messages

                    const user_id = res.user_id

                    dispatch(ACTN_logged_in({ user_data, type: '' }));

                    await API_get_user_messages_count(user_id).then(async msgs => {
                        // console.log('msgs', msgs);
                    });


                    // let is_admin = false;
                    // let is_teacher = false;
                    // let is_pending_teacher = false;

                    if (pathname.indexOf('/teacher') == -1) {

                        let redirect_to = '';

                        switch (user_data.user_role) {
                            case 'admin':
                                // is_admin = true;
                                redirect_to = '/admin';
                                break;
                            case 'instructor':
                                // is_teacher = true;
                                redirect_to = '/account/sessions';
                                break;
                            // case 'pending_instructor':
                            // is_pending_teacher = true;
                            // redirect_to = '/account';
                            // break;
                            default:
                                redirect_to = '/account';
                        }
                        window.location.href = redirect_to;
                    } else {
                        dispatch(ACTN_set_loading(false));
                    }


                    // navigate(redirect_to);

                }

                dispatch(ACTN_hide_login());
            })
    }

    return (

        <form action="" method="post" className="was-validated login_form" >
            <div className='form_inner'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <span className='text-danger'></span>
                    <p className="text-center text-dark p-0 m-0">Please Login</p>
                    <span className='text-danger display-3 hand' onClick={() => dispatch(ACTN_hide_login())}>&times;</span>
                </div>

                <div className="form-group m-3">
                    <label htmlFor="email">Email</label>
                    <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" required />
                </div>

                <div className="form-group m-3">
                    <label htmlFor="paasswrd">password</label>
                    <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" autoComplete='false' required />
                </div>

                <div className='form-group m-3'>
                    <label>
                        <input type='checkbox' className='form-check-input' onChange={handle_remember} />
                        <span className='mx-2'>💾 Remember Me </span>
                    </label>
                </div>

                <div className="form-group m-3">
                    <p className='text-danger'>{error}</p>
                </div>

                <div className="btn-group m-3 d-flex justify-content-between">
                    <input type="button" className="btn btn-primary" value='Login' name="login" onClick={handle_user_login} />
                </div>

                <div className="btn-group m-3 d-flex justify-content-end text-danger" onClick={() => dispatch(ACTN_show_forget_pass())}>
                    Forget Password
                </div>

                <div className="btn-group m-3 d-flex justify-content-between">
                    <button type="button" className="btn btn-warning" onClick={() => dispatch(ACTN_show_register())}>Create Account</button>
                </div>
            </div>
        </form >

    )
}
