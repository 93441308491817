import { useState, useEffect } from 'react';

// react-bootstrap
import { Alert, Button, InputGroup, Col, Container, Form, FormCheck, FormControl, FormGroup, FormLabel, Row, FormFloating } from "react-bootstrap";

// Redux
import { useDispatch } from 'react-redux';
import { ACTN_set_loading, ACTN_set_app_settings } from '../../../Redux/SettingsReducer';

// APIs
import { API_save_settings } from '../../../apis'

// react-toastify
import { toast } from 'react-toastify';


export default function Home({ current_settings }) {

    const [settings, setSettings] = useState({})

    const dispatch = useDispatch();


    useEffect(() => {
        setSettings(current_settings);
    }, [current_settings])


    const handle_settings_changed = e => {
        const k = e.target.id;
        // console.log(k)
        let v;

        if (e.target.type == 'checkbox') {
            v = e.target.checked ? 'yes' : 'no';
        } else {
            v = e.target.value;
        }

        // console.log(e.target.checked)
        setSettings({ ...settings, [k]: v })
    }

    const handle_save_setting = async () => {
        // console.log('settings', settings)
        dispatch(ACTN_set_loading(true));

        await API_save_settings(settings).then(res => {
            dispatch(ACTN_set_loading(false));

            if (res) {
                dispatch(ACTN_set_app_settings(settings));
                toast.success('Settings Updated')
            }

        });
    }

    // console.log('current_settings in HOME', current_settings)
    if (!current_settings || Object.keys(current_settings).length == 0) {
        return
    }

    return (
        <Container className='py-3'>
            <small>

                <Alert>
                    <p>Set the start numbers for Home page statistics</p>
                    <p><small>0 Means the actual statistics</small></p>
                </Alert>

                <div className='d-md-flex align-items-end mb-3 pb-2 border-bottom'>
                    <Form.Check
                        className='me-3'
                        type="switch"
                        id="home__statistics_section_show_teachers"
                        value='yes'
                        label="Show Number of Teachers on Home Page"
                        checked={settings.home__statistics_section_show_teachers == 'yes' && true}
                        onChange={handle_settings_changed}
                    />
                    {
                        settings.home__statistics_section_show_teachers == 'yes' &&
                        <FormFloating>
                            <FormControl placeholder=' ' id='home__statistics_section_teachers_count' value={settings.home__statistics_section_teachers_count} onChange={handle_settings_changed} />
                            <FormLabel>Teachers From</FormLabel>
                        </FormFloating>
                    }
                </div>

                <div className='d-md-flex align-items-center mb-3 pb-2 border-bottom'>
                    <Form.Check
                        className='me-3'
                        type="switch"
                        id="home__statistics_section_show_students"
                        value='yes'
                        label="Show Number of Students on Home Page"
                        checked={settings.home__statistics_section_show_students == 'yes' && true}
                        onChange={handle_settings_changed}
                    />
                    {
                        settings.home__statistics_section_show_students == 'yes' &&
                        <FormFloating>
                            <FormControl placeholder=' ' id='home__statistics_section_students_count' value={settings.home__statistics_section_students_count} onChange={handle_settings_changed} />
                            <FormLabel>Students From</FormLabel>
                        </FormFloating>
                    }

                </div>

                <div className='d-md-flex align-items-center mb-3 pb-2 border-bottom'>
                    <Form.Check
                        className='me-3'
                        type="switch"
                        id="home__statistics_section_show_hours"
                        value='yes'
                        label="Show Number of Delivered Hours on Home Page"
                        checked={settings.home__statistics_section_show_hours == 'yes' && true}
                        onChange={handle_settings_changed}
                    />
                    {
                        settings.home__statistics_section_show_hours == 'yes' &&
                        <FormFloating>
                            <FormControl placeholder=' ' id='home__statistics_section_hours_count' value={settings.home__statistics_section_hours_count} onChange={handle_settings_changed} />
                            <FormLabel>Hours From</FormLabel>
                        </FormFloating>
                    }

                </div>

                <hr />

                <Container>
                    <h2>Home Page Images</h2>
                    <Container className='mx-5'>

                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Banner</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <hr />

                        <h3>Discover Section</h3>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Our Courses</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Our Teachers</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <hr />

                        <h3>Members Services Section</h3>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Login</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Register</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Account</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Label>Admin</Form.Label>
                            <Form.Control type="file" />
                        </Form.Group>
                    </Container>
                </Container>

                <Container>
                    <h2>About Section</h2>
                    <Container className='mx-4'>

                        <FormGroup>
                            <FormLabel>Title</FormLabel>
                            <FormControl types='text' id='home__about_section_title' value={settings.home__about_section_title} onChange={handle_settings_changed} required />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Subtitle</FormLabel>
                            <FormControl types='text' id='home__about_section_subtitle' value={settings.home__about_section_subtitle} onChange={handle_settings_changed} required />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel>Content</FormLabel>
                            <FormControl as='textarea' id='home__about_section_content' value={settings.home__about_section_content} onChange={handle_settings_changed} required />
                        </FormGroup>
                    </Container>

                </Container>


                <Container>
                    <h2>Join as Teacher Section</h2>

                    <div className=' '>
                        <Form.Check
                            className='me-3'
                            type="switch"
                            id="home__teacher_section_show"
                            value='yes'
                            label="Show Join as Teacher on Home Page"
                            checked={settings.home__teacher_section_show == 'yes' ? true : false}
                            onChange={handle_settings_changed}
                        />
                        {
                            settings.home__teacher_section_show == 'yes' &&
                            <>
                                <FormGroup>
                                    <FormLabel>Title</FormLabel>
                                    <FormControl types='text' id='home__teacher_section_title' value={settings.home__teacher_section_title} onChange={handle_settings_changed} />
                                </FormGroup>

                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                    <Form.Label>Background Image</Form.Label>
                                    <Form.Control type="file" />
                                </Form.Group>
                            </>
                        }
                    </div>

                </Container>




                <Button className='my-3' onClick={handle_save_setting}>Save Settigns</Button>
            </small>
        </Container>
    )
}
