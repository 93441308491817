import { useEffect, useState } from 'react'

// react Bootstrap
import { Button, Col, Container, Row } from 'react-bootstrap'

// react-router-dom
import { Link, useNavigate } from 'react-router-dom';

// APIs
import { API_home_page_statistics } from '../apis';

// Icons
import { FaGraduationCap, FaChalkboardTeacher } from 'react-icons/fa';
import { GiTeacher } from 'react-icons/gi';

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { ACTN_show_login, ACTN_show_register } from '../Redux/SettingsReducer';
import { ACTN_logged_out } from '../Redux/UserReducer';

// Imgs
import banner_img from '../assets/imgs/banner_07.jpg';
import courses_img from '../assets/imgs/courses_01.jpg';
import instructors_img from '../assets/imgs/instructors_01.jpg';
import login_img from '../assets/imgs/login.jpg';
import register_img from '../assets/imgs/register.jpg';
import admin_img from '../assets/imgs/admin.jpg';
import account_img from '../assets/imgs/account.jpg';

// Icons
import { BsCalendarCheck } from 'react-icons/bs'
import { MdOutlinePermDataSetting } from 'react-icons/md'
import { FaPeopleArrows } from 'react-icons/fa'
import { RiHomeWifiLine } from 'react-icons/ri'

export default function Home() {
    const [statistics, setStatistics] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { name, loggedIn, isAdmin, isTeacher, isPendingTeacher } = useSelector(state => state.user);
    const { app_name,
        home__statistics_section_show_teachers,
        home__statistics_section_show_students,
        home__statistics_section_show_hours,
        home__statistics_section_teachers_count,
        home__statistics_section_students_count,
        home__statistics_section_hours_count,
        home__about_section_subtitle,
        home__about_section_title,
        home__about_section_content,
        home__teacher_section_show,
        home__teacher_section_title,
        home__teacher_section_bg } = useSelector(state => state.settings.app_settings);

    useEffect(() => {
        fnc_home_page_statistics();
    }, [])

    const fnc_home_page_statistics = async () => {
        await API_home_page_statistics().then(res => {
            setStatistics(res);
        })
    }

    return (
        <Container fluid>
            <div className='d-md-flex justify-content-around align-items-center'>
                <h1 className='text-center'>Welcome {name} to {app_name}</h1>
                {
                    loggedIn &&
                    <h6><small className='text-danger hand' onClick={() => dispatch(ACTN_logged_out())}>LOGOUT</small></h6>
                }
                {
                    !loggedIn &&
                    <h6><small className='text-danger hand' onClick={() => dispatch(ACTN_show_login())}>LOGIN</small></h6>
                }
            </div>

            <Row className='bg-fancy_gray home_banner'>
                <Row className='align-items-center justify-content-center'>
                    <Col md={6} className='order-md-2'>
                        {/* Home Banner */}
                        <div className=''>
                            <img src={banner_img} />
                        </div>
                    </Col>

                    <Col md={6} className='order-md-1 align-items-center justify-content-center'>
                        {/* Start Now */}
                        <Container className='mt-4  txt-dark_gray align-items-center justify-content-center'>
                            <h3 className='mb-3 mx-lg-5 txt-orange'>Contact your instructor now<br />and increase your skills</h3>
                            <Row className='mx-lg-5'>
                                <Col md={6} className='my-2'>
                                    <div className='d-flex align-items-center'>
                                        <BsCalendarCheck className='txt-orange' size={40} />
                                        <h5 className='mx-3'>Create your own schedule</h5>
                                    </div>
                                </Col>
                                <Col md={6} className='my-2'>
                                    <div className='d-flex align-items-center'>
                                        <MdOutlinePermDataSetting className='txt-orange' size={40} />
                                        <h5 className='mx-3'>Customize the Course Content</h5>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mx-lg-5'>
                                <Col md={6} className='my-2'>
                                    <div className='d-flex align-items-center'>
                                        <FaPeopleArrows className='txt-orange' size={40} />
                                        <h5 className='mx-3'>Private One-to-One Sessions</h5>
                                    </div>
                                </Col>
                                <Col md={6} className='my-2'>

                                    <div className='d-flex align-items-center display-6'>
                                        <RiHomeWifiLine className='txt-orange  display-6' size={40} />
                                        <h5 className='mx-3'>Learn From Your Comfort Zone</h5>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='my-5'>
                                <Col>
                                    <div className='d-flex justify-content-center'>
                                        <button onClick={() => { loggedIn ? navigate('/account/teachers') : dispatch(ACTN_show_register()) }} className='start_now'>Start Now</button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </Col>
                </Row>

            </Row>

            {/* Discover */}
            <Container className='my-5'>
                <h2 className='text-center txt-orange'>Discover</h2>

                <Row>

                    <Col md={4}>
                        <div className='hand' onClick={() => navigate('/courses')}>
                            <img className='mw-100 mb-4 rounded-5 shadow-lg border border-5 border-light' src={courses_img} />
                            <h3 className='text-center'>Our Courses</h3>
                        </div>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                        <div className='hand' onClick={() => navigate(loggedIn && !isAdmin ? '/account/teachers' : '/teachers')}>
                            <img className='mw-100 mb-4 rounded-5 shadow-lg border border-5 border-light' src={instructors_img} />
                            <h3 className='text-center'>Our Instructors</h3>
                        </div>
                    </Col>

                </Row>
            </Container>

            {/* Statistics */}
            {
                (home__statistics_section_show_teachers || home__statistics_section_show_students || home__statistics_section_show_hours) &&

                <Container >

                    <h2 className='text-center txt-orange'>Statistics</h2>
                    <Row className='py-5 mx-5 bg-white shadow'>

                        {
                            home__statistics_section_show_students == 'yes' &&
                            <Col>
                                <div className='d-flex flex-column align-items-center'>
                                    <FaGraduationCap className='text-warning display-3' />
                                    <h3>{parseInt(statistics.Students) + parseInt(home__statistics_section_students_count)} +</h3>
                                    <h4>Customers</h4>
                                </div>
                            </Col>
                        }

                        {
                            home__statistics_section_show_teachers == 'yes' &&
                            <Col>
                                <div className='d-flex flex-column align-items-center'>
                                    <GiTeacher className='text-warning display-3' />
                                    <h3>{parseInt(statistics.Teachers) + parseInt(home__statistics_section_teachers_count)} +</h3>
                                    <h4>Instructors</h4>
                                </div>
                            </Col>
                        }

                        {
                            home__statistics_section_show_hours == 'yes' &&
                            <Col>
                                <div className='d-flex flex-column align-items-center'>
                                    <FaChalkboardTeacher className='text-warning display-3' />
                                    <h3>{parseInt(statistics.Hours) + parseInt(home__statistics_section_hours_count)} +</h3>
                                    <h4>Hours</h4>
                                </div>
                            </Col>
                        }
                    </Row>
                </Container>
            }
            {/* Members Services */}
            {/* Login/Register */}
            {
                !loggedIn &&
                <Container className='my-5'>
                    <h2 className='text-center txt-orange'>Members Services</h2>

                    <Row>
                        <Col md={3}></Col>
                        <Col md={3}>
                            <div className='hand' onClick={() => { dispatch(ACTN_show_login()) }}>
                                <img className='mw-100' src={login_img} />
                                <h3 className='text-center'>Login</h3>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className='hand' onClick={() => { dispatch(ACTN_show_register()) }}>
                                <img className='mw-100' src={register_img} />
                                <h3 className='text-center'>Register</h3>
                            </div>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </Container>
            }

            {/*  My Account*/}
            {
                (loggedIn && !isAdmin) &&
                <Container className='my-5'>
                    <h2 className='text-center txt-orange'>Members Services</h2>

                    <Row>
                        <Col md={4}></Col>
                        <Col md={4}>
                            <div className='hand' onClick={() => { navigate('account') }}>
                                <img className='mw-100' src={account_img} />
                                <h3 className='text-center'>My Account</h3>
                            </div>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                </Container>
            }


            {/*  My Admin Page*/}
            {
                (loggedIn && isAdmin) &&
                <Container className='my-5'>
                    <h2 className='text-center txt-orange'>Admin Services</h2>

                    <Row>
                        <Col md={4}></Col>
                        <Col md={4}>
                            <div className='hand' onClick={() => { navigate('admin') }}>
                                <img className='mw-100' src={admin_img} />
                                <h3 className='text-center'>Admin Page</h3>
                            </div>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                </Container>
            }

            {/* About */}
            <Container fluid>
                <Row className='py-0'>
                    <Col md={4}>
                        <div className='d-flex flex-column h-100 justify-content-center align-items-center text-primary'>
                            <h2>{home__about_section_subtitle}</h2>
                            <h2>{home__about_section_title}</h2>
                        </div>
                    </Col>
                    <Col md={8}>
                        <p className='py-5'>
                            {home__about_section_content}
                        </p>
                    </Col>
                </Row>

            </Container>

            {/* APPLY TODAY AND START TEACHING */}
            {
                // console.log('loggedIn , isAdmin , isTeacher , isPendingTeacher', loggedIn, isAdmin, isTeacher, isPendingTeacher)
            }
            {
                // console.log('loggedIn && !isAdmin && !isTeacher && !isPendingTeacher', loggedIn && !isAdmin && !isTeacher && !isPendingTeacher)
            }
            {
                // true && !false && !false && !false
                // true && true && true & true

                (loggedIn && !isAdmin && !isTeacher && !isPendingTeacher && home__teacher_section_show) &&

                <Container fluid>
                    <Row className='apply_teacher'>

                        <Col md={9}>
                            <h2 className='text-light'>{home__teacher_section_title}</h2>
                        </Col>

                        <Col md={3}>
                            <Link className='btn btn-outline-warning btn-lg' to='join-as-instructor'>Apply Now</Link>
                        </Col>

                    </Row>
                </Container>
            }

        </Container>
    )
}
