import { createSlice } from "@reduxjs/toolkit";

const name = "vars";
const initialState = {
    app_title: "Booking Hourse",
    app_description: "Booking Hourse",
    app_keywords: "Booking Hourse",
    app_author: "MWJB WEB SOLUTIONS",
    app_copyright: "Booking Hourse",
}

const VarsReducer = createSlice({
    name,
    initialState,
    reducers: {}
});

export default VarsReducer.reducer;

