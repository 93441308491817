import React from 'react'

// React Bootstrap
import { Container } from 'react-bootstrap'

export default function CoursesList({ courses, handle_course_changed }) {


    return (
        <Container fluid>

            <div className='courses_container'>
                {
                    courses.map(({ course_id, course_name, course_description }) => {

                        return (
                            <div className='course_box'>
                                <div className='course_title' key={course_id} onClick={() => handle_course_changed(course_id, course_name)}>
                                    <span>{course_name}</span>
                                </div>
                                <div className='course_description'>
                                    {course_description}
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </Container>

    )
}
